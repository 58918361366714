import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCQID0Z-cLq3VUWAZaLbsHrHgL0gGrGoY8",
  authDomain: "vue-muso-ninjas-659ba.firebaseapp.com",
  projectId: "vue-muso-ninjas-659ba",
  storageBucket: "vue-muso-ninjas-659ba.appspot.com",
  messagingSenderId: "245789644619",
  appId: "1:245789644619:web:3dc04602e558a7dc0e7ace",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const fireStoreDb = firebase.firestore();
const fireAuth = firebase.auth();
const fireStorage = firebase.storage();

// init timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { fireStoreDb, fireAuth, timestamp, fireStorage };
