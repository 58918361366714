import { ref } from "vue";
import { fireAuth } from "../firebase/config";

// refs
const error = ref(null);
const isPending = ref(false);

// logout function
const logout = async () => {
  error.value = null;
  isPending.value = true;

  try {
    await fireAuth.signOut();
    isPending.value = false;
  } catch (err) {
    console.log(err.message);
    error.value = err.message;
    isPending.value = false;
  }
};

const useLogout = () => {
  return { error, logout };
};

export default useLogout;
