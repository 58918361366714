import { ref } from "vue";
import { fireStoreDb } from "../firebase/config";

const useDocument = (collection, id) => {
  const error = ref(null);
  const isPending = ref(false);

  let docRef = fireStoreDb.collection(collection).doc(id);

  const deleteDoc = async () => {
    isPending.value = true;
    error.value = null;

    try {
      const response = await docRef.delete();
      isPending.value = false;

      return response;
    } catch (err) {
      console.log(err.message);
      error.value = "Could not delete the data";
      isPending.value = false;
    }
  };

  const updateDoc = async (data) => {
    isPending.value = true;
    error.value = null;

    try {
      const response = await docRef.update(data);
      isPending.value = false;

      return response;
    } catch (err) {
      console.log(err.message);
      error.value = "Could not update the data";
      isPending.value = false;
    }
  };

  return { error, isPending, deleteDoc, updateDoc };
};

export default useDocument;
