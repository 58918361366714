import { ref } from "vue";
import { fireAuth } from "../firebase/config";

// refs
const user = ref(fireAuth.currentUser);

// auth changes
fireAuth.onAuthStateChanged((_user) => {
  console.log("User state change. Current user is:", _user);
  user.value = _user;
});

const getUser = () => {
  return { user };
};

export default getUser;
